import React from 'react';
import Colours from './Colours';
import { useDocumentTitle } from "../../hooks/setDocumentTitle"
function ColourChart() {
    useDocumentTitle("Colour Chart | Witty Crafts Design");
    const affiliate = "utm_medium=affiliate&a_aid=c474ca6e";

    return (
        <>
            <h1>Colour Chart</h1>
            <p>These are the <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?${affiliate}`} target="_blank">Stylecraft colours</a> used for the <a href="tools/granny-square-colour-picker">Granny Square Colour Picker</a>.</p>
            <div className="row">
                {Colours.map(colour =>
                    <div key={colour.hex} className="card border-0 col-md-2">
                        <div className="card-img-top mb-3" style={{width: '100%', height: '100px', backgroundColor: colour.hex, boxShadow: '0 4px 8px #ced4da'}}></div>
                        <div className="card-body">
                            <div className="card-title h5">
                                <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_${colour.code}&${affiliate}`} target="_blank">{colour.name} - {colour.code}</a></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default ColourChart