import React from 'react';
import { useDocumentTitle } from "../../hooks/setDocumentTitle"

function Tools() {
    useDocumentTitle("Tools | Witty Crafts Design");
    return (
        <>
            <h1>Tools</h1>
            <ul className="list-unstyled list-linktree">
                <li><a className="btn btn-primary m-1 w-75" href="/tools/colour-chart">Colour Chart</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="/tools/granny-square-colour-picker">Granny Square Colour Picker</a></li>
            </ul>
        </>
    );
}
export default Tools