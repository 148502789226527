import React from 'react';
import { useDocumentTitle } from "../../hooks/setDocumentTitle"

function SpicedBiscuitsBlanket() {
    useDocumentTitle("Spiced Biscuits Blanket | Witty Crafts Design");
    const combinations = [
        { "row": ["1", "E", "A", "C", "D", "B"] },
        { "row": ["2", "C", "D", "B", "A", "E"] },
        { "row": ["3", "B", "A", "E", "C", "D"] },
        { "row": ["4", "C", "E", "D", "B", "A"] },
        { "row": ["5", "A", "D", "B", "E", "C"] },
        { "row": ["6", "C", "E", "A", "D", "B"] },
        { "row": ["7", "E", "C", "B", "A", "D"] },
        { "row": ["8", "C", "B", "E", "D", "A"] },
        { "row": ["9", "D", "A", "E", "B", "C"] },
        { "row": ["10", "E", "B", "D", "C", "A"] },
        { "row": ["11", "D", "C", "A", "E", "B"] },
        { "row": ["12", "E", "D", "A", "C", "E"] },
        { "row": ["13", "B", "C", "E", "A", "D"] },
        { "row": ["14", "E", "D", "C", "B", "A"] },
        { "row": ["15", "D", "E", "B", "C", "E"] },
        { "row": ["16", "E", "A", "D", "B", "C"] },
        { "row": ["17", "C", "E", "B", "A", "D"] },
        { "row": ["18", "B", "C", "A", "D", "E"] },
        { "row": ["19", "E", "A", "D", "B", "C"] },
        { "row": ["20", "B", "E", "C", "D", "A"] },
        { "row": ["21", "A", "B", "D", "E", "B"] },
        { "row": ["22", "C", "A", "B", "C", "D"] },
        { "row": ["23", "E", "B", "C", "D", "A"] },
        { "row": ["24", "A", "D", "B", "E", "C"] },
        { "row": ["25", "E", "C", "A", "E", "B"] },
        { "row": ["26", "C", "A", "D", "B", "A"] },
        { "row": ["27", "B", "E", "A", "C", "D"] },
        { "row": ["28", "D", "A", "C", "B", "E"] },
        { "row": ["29", "E", "D", "B", "A", "C"] },
        { "row": ["30", "A", "C", "D", "E", "B"] },
        { "row": ["31", "B", "D", "A", "B", "C"] },
        { "row": ["32", "D", "E", "C", "A", "B"] },
        { "row": ["33", "B", "E", "D", "A", "C"] },
        { "row": ["34", "D", "B", "A", "C", "E"] },
        { "row": ["35", "A", "C", "E", "D", "B"] },
        { "row": ["36", "E", "B", "A", "C", "D"] },
        { "row": ["37", "D", "C", "E", "B", "A"] },
        { "row": ["38", "C", "D", "A", "C", "E"] },
        { "row": ["39", "E", "B", "C", "A", "D"] },
        { "row": ["40", "B", "A", "D", "C", "E"] },
        { "row": ["41", "A", "D", "C", "B", "D"] },
        { "row": ["42", "E", "A", "B", "D", "C"] },
        { "row": ["43", "D", "B", "C", "E", "A"] },
        { "row": ["44", "B", "A", "C", "D", "E"] },
        { "row": ["45", "A", "E", "D", "C", "B"] },
        { "row": ["46", "D", "A", "B", "E", "C"] },
        { "row": ["47", "C", "D", "E", "B", "A"] },
        { "row": ["48", "A", "E", "C", "D", "B"] },
        { "row": ["49", "D", "E", "A", "C", "B"] },
        { "row": ["50", "B", "D", "C", "A", "E"] },
        { "row": ["51", "C", "A", "E", "D", "B"] },
        { "row": ["52", "E", "C", "D", "B", "C"] },
        { "row": ["53", "C", "B", "A", "E", "D"] },
        { "row": ["54", "B", "D", "E", "C", "A"] },
        { "row": ["55", "A", "C", "B", "D", "E"] },
        { "row": ["56", "D", "B", "E", "A", "C"] },
        { "row": ["57", "B", "A", "D", "E", "C"] },
        { "row": ["58", "A", "B", "E", "C", "D"] },
        { "row": ["59", "D", "C", "B", "E", "A"] },
        { "row": ["60", "A", "D", "E", "C", "B"] },
        { "row": ["61", "B", "C", "D", "A", "E"] },
        { "row": ["62", "A", "E", "B", "D", "C"] },
        { "row": ["63", "C", "B", "D", "E", "A"] },
        { "row": ["64", "A", "E", "C", "B", "D"] }
    ];

    const affiliate = "&utm_medium=affiliate&a_aid=c474ca6e";

    return (
        <>
            <h1>Spiced Biscuits Blanket</h1>
            <div className="text-center">
            <img src="/media/spiced_biscuits_blanket.png" className="img-fluid" />
            <ul className="list-inline m-3 font-weight-bold">
                <li className="list-inline-item">
                    <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1001${affiliate}`} target="_blank">A - White (1001)</a>
                </li>
                <li className="list-inline-item">
                    <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1218${affiliate}`} target="_blank"> B - Parchment (1218)</a>
                </li>
                <li className="list-inline-item">
                    <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1820${affiliate}`} target="_blank"> C - Duck Egg (1820)</a>
                </li>
                <li className="list-inline-item">
                    <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1709${affiliate}`} target="_blank"> D - Gold (1709)</a>
                </li>
                <li className="list-inline-item">
                    <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1035${affiliate}`} target="_blank"> E - Burgundy (1035)</a>
                </li>
                </ul>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Square</th>
                            <th>Round 1</th>
                            <th>Round 2</th>
                            <th>Round 3</th>
                            <th>Round 4</th>
                            <th>Round 5</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {combinations.map(item => 
                            <tr>
                                <td>{item.row[0]}</td>
                                <td>{item.row[1]}</td>
                                <td>{item.row[2]}</td>
                                <td>{item.row[3]}</td>
                                <td>{item.row[4]}</td>
                                <td>{item.row[5]}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export default SpicedBiscuitsBlanket