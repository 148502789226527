import React, { useState } from 'react';
import Footer from './Footer';

function Layout({ children }) {

    const [navMenuCssClass, setNavMenuCssClass] = useState("wrapper");

    function toggleNavMenu() {

        if (navMenuCssClass === "wrapper") {
            setNavMenuCssClass("wrapper toggled");
        } else {
            setNavMenuCssClass("wrapper");
        }
    }

    return (
        <div id="wrapper" className={navMenuCssClass}>
            <div id="page-content-wrapper">
                <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                        <li><a href="/">Home</a></li>
                        <li><a href="patterns">Patterns</a></li>
                        <li><a href="free-patterns">Free Patterns</a></li>
                        <li><a href="tools">Tools</a></li>
                    </ul>
                </div>
                <header id="top">
                    <button id="menu-toggle" className="btn btn-primary" onClick={toggleNavMenu}>
                        <span className="visually-hidden">Menu</span>
                        <span className="icon-menu"></span>
                    </button>
                    <a href="/" title="Home" id="brand-heading">Witty Crafts Design</a>
                    <ul id="header-nav" className="list-inline float-end m-2 d-none d-md-inline">
                        <li className="list-inline-item"><a href="/">Home</a></li>
                        <li className="list-inline-item"><a href="patterns">Patterns</a></li>
                        <li className="list-inline-item"><a href="free-patterns">Free Patterns</a></li>
                        <li className="list-inline-item"><a href="tools">Tools</a></li>
                    </ul>
                </header>
                <main role="main" className="content">
                    <div className="container">
                        {children}
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
}
export default Layout