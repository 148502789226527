import React from "react";

function Footer() {
    const year = new Date().getFullYear();
    const scrollTop = () => document.getElementById('top').scrollIntoView({ behavior: 'smooth' });

    return (
        <footer className="footer pt-2">
            <div className="text-center d-print-none">
                <ul className="list-inline list-social-link">
                    <li className="list-inline-item">
                        <a href="https://www.instagram.com/wittycraftsdesign" title="Instagram" target="_blank">
                            <span className="icon icon-instagram"><span className="visually-hidden">Instagram</span></span>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="https://www.ravelry.com/stores/sylwia-witty-designs" title="Ravelry" target="_blank">
                            <span className="icon icon-ravelry"><span className="visually-hidden">Ravelry</span></span>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href="https://wittycraftsdesign.etsy.com" title="Etsy" target="_blank">
                            <span className="icon icon-etsy"><span className="visually-hidden">Etsy</span></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="container pb-2">
                &copy; 2021 - {year} Witty Crafts Design
                <button type="button" id="scroll_top" className="btn btn-primary float-end d-print-none" onClick={scrollTop}><span className="visually-hidden">top</span><span className="icon-up-open"></span></button>
            </div>
        </footer>
    )
}
export default Footer;