import React from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle"
function Home() {

    useDocumentTitle("Welcome | Witty Crafts Design");
    return (
        <>
            <h1>Welcome</h1>
            <ul className="list-linktree">
                <li><a className="btn btn-primary m-1 w-75" href="https://www.ravelry.com/designers/sylwia-witty" target="_blank">Ravelry</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="https://www.lovecrafts.com/en-gb/user/Witty-Crafts-Design/patterns/1a543bf4-dedd-42d0-989c-bb897eab9533" target="_blank">LoveCrafts</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="https://wittycraftsdesign.etsy.com" target="_blank">Etsy</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="https://amzn.to/3VZVnYo" target="_blank">Yarn Cutter</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="https://www.lovecrafts.com/en-gb/l/yarns?utm_medium=affiliate&a_aid=c474ca6e" target="_blank">I buy my yarn from here</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="/patterns">Patterns</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="/free-patterns">Free Patterns</a></li>
                <li><a className="btn btn-primary m-1 w-75" href="/Tools/granny-square-colour-picker">Granny Square Colour Picker</a></li>
            </ul>
        </>
    );
}
export default Home