import React from 'react';
import { useDocumentTitle } from "../../hooks/setDocumentTitle"

function Tips() {
    useDocumentTitle("Free Patterns | Witty Crafts Design");
    return (
        <>
            <h1>Free Patterns</h1>
            <section>
                <div className="row">
                    <div className="card border-0 col-sm-12 col-md-4">
                        <img src="media/pom-pom-border-cover.jpg" className="img-fluid rounded shadow" alt="pom pom border" />
                        <div className="card-body mt-2">
                            <h2 className="card-title">
                                <a href="/free-patterns/pom-pom-border">Pom Pom Border</a>
                            </h2>
                            <p className="card-text">
                                Who doesn't like a pom pom border, or a pom pom for that matter. A few years ago I made my Cath Kidston inspired granny square blanket. It still remains one of my favourite blankets, which was most enjoyable to work on.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Tips