import React, { useState } from 'react';
import Colours from '../components/tools/Colours';

function ColourPicker(props) {

    const [selectedColour, setSelectedColour] = useState({ name: "None", code: "", hex: "#ffffff" });
    const [showModal, setShowModal] = useState(false);

    function handleOnClick(colour, event) {
        setSelectedColour(colour);
        setShowModal(false);
        event.target.name = props.name;
        event.target.value = colour.hex;
        props.onChange(event);
    }

    function handleOnClearClick(event) {

        const colour = { hex : '', code: '', name: 'None' };
        setSelectedColour(colour);
        setShowModal(false);
        event.target.name = props.name;
        event.target.value = colour.hex;
        props.onChange(event);
    }

    function toggleShowModal()
    {
        if (showModal) {
            setShowModal(false);
        } else {
            setShowModal(true);
        }
    }
    // TODO move to seperate component
    function ColourOption(props) {
        return <div className="col-md-4 col-6">
            <div className="colour-picker" style={{ backgroundColor: props.colour.hex }} onClick={(event) => handleOnClick(props.colour, event)}>
            </div>
            <span>{props.colour.name} {props.colour.code}</span>
        </div>
    }

    return (
        <>
            <div className="m-auto text-center">
                <div onClick={toggleShowModal} className="colour-picker" style={{ backgroundColor: selectedColour.hex }}></div>
                <span className="lh-sm">{selectedColour.name} {selectedColour.code}</span>
            </div>
            <div className="modal fade show" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Select Colours</h5>
                            <button type="button" onClick={toggleShowModal} className="btn-close" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4 col-6">
                                    <div className="colour-picker" style={{ backgroundColor: 'none' }} onClick={handleOnClearClick}>
                                        
                                    </div>
                                    <span>None</span>
                                </div>
                                {Colours.map(colour =>
                                    <ColourOption key={colour.code} colour={colour} />
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={toggleShowModal} className="btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} export default ColourPicker