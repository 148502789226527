import React from 'react';
import { useDocumentTitle } from "../../hooks/setDocumentTitle"

function PomPomBorder() {
    useDocumentTitle("Pom Pom Border | Witty Crafts Design");
    const affiliate = "&utm_medium=affiliate&a_aid=c474ca6e";

    return (
        <>
            <h1>Pom Pom Border</h1>
            <div className="text-left">
                <p>Who doesn't like a pom pom border, or a pom pom for that matter.</p>
                <p>A few years ago I made my Cath Kidston inspired granny square blanket. It still remains one of my favourite blankets, which was most enjoyable to work on.</p>
                <p>The blanket is made of 120 granny squares (10x 12 squares) and it measures 140cm x 170 cm, each square has 7 rounds. I used Stylecraft Special DK in White, Lipstick, Candy Floss, Lemon, Cloud Blue and Meadow which I purchased from <a href="https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?utm_medium=affiliate&a_aid=c474ca6e" target="_blank">LoveCrafts</a>.</p>
                <p>
                    I followed two colour combinations:
                    <ul className="list-inline m-3 font-weight-bold">
                        <li className="list-inline-item">
                            <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1001${affiliate}`} target="_blank">White (1001)</a>
                        </li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1246${affiliate}`} target="_blank">Lipstick (1246)</a></li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1130${affiliate}`} target="_blank">Candy Floss (1130)</a></li>
                    <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1020${affiliate}`} target="_blank">Lemon (1020)</a></li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1019${affiliate}`} target="_blank">Cloud Blue (1019)</a></li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1065${affiliate}`} target="_blank">Meadow (1065)</a></li>
                        <li className="list-inline-item">
                            <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1001${affiliate}`} target="_blank">White (1001)</a>
                        </li>
                    </ul>
                    And
                    <ul className="list-inline m-3 font-weight-bold">
                        <li className="list-inline-item">
                            <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1001${affiliate}`} target="_blank">White (1001)</a>
                        </li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1065${affiliate}`} target="_blank">Meadow (1065)</a></li>
                            <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1019${affiliate}`} target="_blank">Cloud Blue (1019)</a></li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1020${affiliate}`} target="_blank">Lemon (1020)</a></li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1130${affiliate}`} target="_blank">Candy FLoss (1130)</a></li>
                        <li className="list-inline-item"><a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1246${affiliate}`} target="_blank">Lipstick (1246)</a></li>
                        <li className="list-inline-item">
                            <a href={`https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?sku=YST023_1001${affiliate}`} target="_blank">White (1001)</a>
                        </li>
                    </ul>
                </p>
                <p>While making the blanket I searched for the perfect border to finish it off. I didn’t want anything too heavy or intricate, that would take away from the blanket itself. When I saw the pom pom border I knew it was exactly what I had been looking for and that it would go perfectly with the blanket, you could say it was love at first sight. It’s such a simple yet effective border and I’m sure that once you’ve used it in one of your projects you will want to come back to it time and time again. I know I have, I've added it to blankets, scarves and shawls, but I just know that there will be others.</p>
                <div className="row">
                    {[...Array(18,19,20,21,22)].map((x, i) =>
                        <div className="card col-sm-12 col-md-4">
                            <img src={`/media/pom-pom-border_photo-${x}.jpg`} alt={`Photo ${x}`} className="img-fluid rounded shadow mb-3" />
                        </div>
                    )}
                </div>
                <p>This border is relatively easy to make, once you’ve made a few pom poms and memorised the pattern, watching the border grow becomes very therapeutic. I need to warn you though,it becomes difficult to put down, as the urge to make yet another pom pom becomes so strong, that you may carry on making them until you’ve reached the end :)</p>
                <p>I've written the instructions on how to make the pom pom border using a granny square blanket, however it can be easily adapted and used with other stitches. I usually skip between two and three stitches when attaching individual pom poms.</p>
                <h3>Instructions</h3>
                <p>Attach yarn as per the photo below and ch 6.</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-1.jpg" alt="Photo 1" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Yarn over, insert hook into 4th chain from the hook, pull through, three loops on the hook, yarn over, pull through two loops, you will have two loops on the hook</p>

                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-2.jpg" alt="Photo 2" className="img-fluid rounded shadow mb-3" />
                    </div>
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-3.jpg" alt="Photo 3" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Yarn over, insert hook into the same stitch, pull through, three loops on the hook, yarn over, pull through two loops, you will have three loops on the hook</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-4.jpg" alt="Photo 4" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Repeat this step until there are five loops on the hook</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-5.jpg" alt="Photo 5" className="img-fluid rounded shadow mb-3" />
                    </div>
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-6.jpg" alt="Photo 6" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Yarn over, pull through all five loops and ch 3</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-7.jpg" alt="Photo 7" className="img-fluid rounded shadow mb-3" />
                    </div>
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-8.jpg" alt="Photo 8" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Yarn over, insert hook into 3rd chain from the hook, pull through, two loops on the hook.</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-8.jpg" alt="Photo 9" className="img-fluid rounded shadow mb-3" />
                    </div>
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-10.jpg" alt="Photo 10" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Repeat this step until there are five loops on the hook.</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-11.jpg" alt="Photo 11" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Yarn over, pull through all five loops.</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-12.jpg" alt="Photo 12" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Fold the cluster in half and insert the hook into the 3rd ch from the beginning chain 6 and secure with a slip stitch</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-13.jpg" alt="Photo 13" className="img-fluid rounded shadow mb-3" />
                    </div>
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-14.jpg" alt="Photo 14" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>Ch 3, skip two stitches and secure with a slip stitch between two clusters of trebles.</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-15.jpg" alt="Photo 15" className="img-fluid rounded shadow mb-3" />
                    </div>
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-16.jpg" alt="Photo 16" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>To attach a pom pom around a corner, I skipped the ch 2 space and worked a slip stitch into the first stitch on the next t3 treble cluster.</p>
                <div className="row">
                    <div className="card col-sm-12 col-md-4">
                        <img src="/media/pom-pom-border_photo-17.jpg" alt="Photo 17" className="img-fluid rounded shadow mb-3" />
                    </div>
                </div>
                <p>I hope that you find my instructions useful and choose to use this border in one of your crochet projects. I would love to see your pom pom border, so if you are on Instagram please tag me <a href="https://www.instagram.com/wittycraftsdesign" target="_blank" className="font-weight-bold">@wittycraftsdesign</a> or use the hashtag <a href="https://www.instagram.com/#wittycraftsdesign" target="_blank" className="font-weight-bold">#wittycraftsdesign</a></p>
            </div>
        </>
    );
}
export default PomPomBorder