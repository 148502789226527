import React from 'react';


    const Colours = [
        {
            "name": "White",
            "hex": "#ffffff",
            "code": "1001"
        },
        {
            "name": "Cream",
            "hex": "#ffffec",
            "code": "1005"
        },
        {
            "name": "Toy",
            "hex": "#eee8c7",
            "code": "1844"
        },
        {
            "name": "Stone",
            "hex": "#d7c492",
            "code": "1710"
        },
        {
            "name": "Buttermilk",
            "hex": "#e4e68d",
            "code": "1835"
        },
        {
            "name": "Camel",
            "hex": "#c0a460",
            "code": "1420"
        },
        {
            "name": "Parchment",
            "hex": "#c3c8a9",
            "code": "1218"
        },
        {
            "name": "Mushroom",
            "hex": "#afafb2",
            "code": "1832"
        },
        {
            "name": "Mocha",
            "hex": "#7a6959",
            "code": "1064"
        },
        {
            "name": "Raspberry",
            "hex": "#a03352",
            "code": "1023"
        },
        {
            "name": "Fondant",
            "hex": "#ea82b0",
            "code": "1241"
        },
        {
            "name": "Pale Rose",
            "hex": "#be8385",
            "code": "1080"
        },
        {
            "name": "Candyfloss",
            "hex": "#f2cad8",
            "code": "1130"
        },
        {
            "name": "Powder Pink",
            "hex": "#e2d4dd",
            "code": "1843"
        },
        {
            "name": "Soft Peach",
            "hex": "#B6A59B",
            "code": "1240"
        },
        {
            "name": "Apricot",
            "hex": "#fee4c4",
            "code": "1026"
        },
        {
            "name": "Vintage Peach",
            "hex": "#d28c75",
            "code": "1836"
        },
        {
            "name": "Pomegranate",
            "hex": "#e4004f",
            "code": "1083"
        },
        {
            "name": "Fushia Purple",
            "hex": "#a0084d",
            "code": "1827"
        },
        {
            "name": "Bright Pink",
            "hex": "#ce004f",
            "code": "1435"
        },
        {
            "name": "Fiesta",
            "hex": "#fe007c",
            "code": "1257"
        },
        {
            "name": "Clematis",
            "hex": "#f3d4fb",
            "code": "1390"
        },
        {
            "name": "Grape",
            "hex": "#744b63",
            "code": "1067"
        },
        {
            "name": "Magenta",
            "hex": "#b22ea2",
            "code": "1084"
        },
        {
            "name": "Boysenberry",
            "hex": "#8a0c55",
            "code": "1828"
        },
        {
            "name": "Duck Egg",
            "hex": "#96d0cd",
            "code": "1820"
        },
        {
            "name": "Sherbet",
            "hex": "#64e2e3",
            "code": "1034"
        },
        {
            "name": "Cloud Blue",
            "hex": "#7cd3f9",
            "code": "1019"
        },
        {
            "name": "Storm Blue",
            "hex": "#2e7a89",
            "code": "1722"
        },
        {
            "name": "Cornish Blue",
            "hex": "#3997c8",
            "code": "1841"
        },
        {
            "name": "Turquoise",
            "hex": "#18d0f6",
            "code": "1068"
        },
        {
            "name": "Teal",
            "hex": "#0d7d6e",
            "code": "1062"
        },
        {
            "name": "Sage",
            "hex": "#49927a",
            "code": "1725"
        },
        {
            "name": "Aspen",
            "hex": "#2de6c9",
            "code": "1422"
        },
        {
            "name": "Spearmint",
            "hex": "#6cd7cd",
            "code": "1842"
        },
        {
            "name": "Cypress",
            "hex": "#487d64",
            "code": "1824"
        },
        {
            "name": "Lincoln",
            "hex": "#7cbd8b",
            "code": "1834"
        },
        {
            "name": "Spring Green",
            "hex": "#aafdad",
            "code": "1316"
        },
        {
            "name": "Meadow",
            "hex": "#809f53",
            "code": "1065"
        },
        {
            "name": "Pistachio",
            "hex": "#aec44d",
            "code": "1822"
        },
        {
            "name": "Lime",
            "hex": "#929117",
            "code": "1712"
        },
        {
            "name": "Walnut",
            "hex": "#472d23",
            "code": "1054"
        },
        {
            "name": "Dark Brown",
            "hex": "#2d1010",
            "code": "1004"
        },
        {
            "name": "Silver",
            "hex": "#9faaac",
            "code": "1203"
        },
        {
            "name": "Grey",
            "hex": "#576a6c",
            "code": "1099"
        },
        {
            "name": "Graphite",
            "hex": "#39474a",
            "code": "1063"
        },
        {
            "name": "Black",
            "hex": "#000000",
            "code": "1002"
        },
        {
            "name": "Lemon",
            "hex": "#e4feb8",
            "code": "1020"
        },
        {
            "name": "Citron",
            "hex": "#e0d53e",
            "code": "1263"
        },
        {
            "name": "Dandelion",
            "hex": "#d8a400",
            "code": "1856"
        },
        {
            "name": "Mustard",
            "hex": "#b09319",
            "code": "1823"
        },
        {
            "name": "Saffron",
            "hex": "#e0b75c",
            "code": "1081"
        },
        {
            "name": "Sunshine",
            "hex": "#eea000",
            "code": "1114"
        },
        {
            "name": "Gold",
            "hex": "#ad7417",
            "code": "1709"
        },
        {
            "name": "Burgendy",
            "hex": "#3b0005",
            "code": "1035"
        },
        {
            "name": "Claret",
            "hex": "#550004",
            "code": "1123"
        },
        {
            "name": "Blush",
            "hex": "#e0989e",
            "code": "1833"
        },
        {
            "name": "Shrimp",
            "hex": "#ea5e61",
            "code": "1132"
        },
        {
            "name": "Clementine",
            "hex": "#fe8d0d",
            "code": "1853"
        },
        {
            "name": "Spice",
            "hex": "#e05c25",
            "code": "1711"
        },
        {
            "name": "Jaffa",
            "hex": "#fe5100",
            "code": "1256"
        },
        {
            "name": "Tomato",
            "hex": "#b00808",
            "code": "1723"
        },
        {
            "name": "Copper",
            "hex": "#822103",
            "code": "1029"
        },
        {
            "name": "Matador",
            "hex": "#d40001",
            "code": "1010"
        },
        {
            "name": "Lipstick",
            "hex": "#a20000",
            "code": "1246"
        },
        {
            "name": "Plum",
            "hex": "#74235c",
            "code": "1061"
        },
        {
            "name": "Parma Violet",
            "hex": "#b8c2cc",
            "code": "1724"
        },
        {
            "name": "Wisteria",
            "hex": "#9b8bd1",
            "code": "1432"
        },
        {
            "name": "Lavender",
            "hex": "#818dca",
            "code": "1188"
        },
        {
            "name": "Bluebell",
            "hex": "#6e8be0",
            "code": "1082"
        },
        {
            "name": "Violet",
            "hex": "#62639c",
            "code": "1277"
        },
        {
            "name": "Proper Purple",
            "hex": "#52327d",
            "code": "1855"
        },
        {
            "name": "Emperor",
            "hex": "#2b034a",
            "code": "1425"
        },
        {
            "name": "Empire",
            "hex": "#034D7C",
            "code": "1829"
        },
        {
            "name": "Petrol",
            "hex": "#00747d",
            "code": "1708"
        },
        {
            "name": "Aster",
            "hex": "#5da4d1",
            "code": "1003"
        },
        {
            "name": "Denim",
            "hex": "#306d8b",
            "code": "1302"
        },
        {
            "name": "Lapis",
            "hex": "#2e5186",
            "code": "1831"
        },
        {
            "name": "Lobelia",
            "hex": "#333c71",
            "code": "1825"
        },
        {
            "name": "Royal",
            "hex": "#060674",
            "code": "1117"
        },
        {
            "name": "French Navy",
            "hex": "#07427a",
            "code": "1854"
        },
        {
            "name": "Midnight",
            "hex": "#070744",
            "code": "1011"
        },
        {
            "name": "Apple",
            "hex": "#82a827",
            "code": "1852"
        },
        {
            "name": "Khaki",
            "hex": "#3f4f28",
            "code": "1027"
        },
        {
            "name": "Bright Green",
            "hex": "#00fe8e",
            "code": "1259"
        },
        {
            "name": "Grass Green",
            "hex": "#60b849",
            "code": "1821"
        },
        {
            "name": "Kelly Green",
            "hex": "#017742",
            "code": "1826"
        },
        {
            "name": "Green",
            "hex": "#006626",
            "code": "1116"
        },
        {
            "name": "Bottle",
            "hex": "#0b582e",
            "code": "1009"
        }
    ];

export default Colours