import Home from "./components/Home";
import Patterns from "./components/patterns/Patterns";
import Tips from "./components/tips/Tips";
import Tools from "./components/tools/Tools";
import SpicedBiscuitsBlanket from "./components/patterns/SpicedBiscuitsBlanket";
import PomPomBorder from "./components/tips/PomPomBorder";
import ColourChart from "./components/tools/ColourChart";
import ColourCombinations from "./components/tools/ColourCombinations"

const AppRoutes = [
  {
    index: true,
    element: <Home />
    },
    {
        path: '/patterns',
        element: <Patterns />
    },
    {
        path: '/patterns/spiced-biscuits-blanket',
        element: <SpicedBiscuitsBlanket />
    },
    {
        path: '/free-patterns',
        element: <Tips />
    },
    {
        path: '/free-patterns/pom-pom-border',
        element: <PomPomBorder />
    },
    {
        path: '/tools',
        element: <Tools />
    },
    {
        path: '/tools/colour-chart',
        element: <ColourChart />
    },
    {
        path: '/tools/granny-square-colour-picker',
        element: <ColourCombinations />
    }
];

export default AppRoutes;
