import React, { useState } from 'react';
import ColourPicker from '../ColourPicker';
import Colours from './Colours';
//import { TestColours1, TestColours2 } from '../../test/TestColours'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useDocumentTitle } from "../../hooks/setDocumentTitle"
import { useParams } from 'react-router-dom';

function ColourCombinations() {

    useDocumentTitle("Granny Square Colour Picker | Witty Crafts Design");

    const LoadingStates = {
        Waiting: "Waiting",
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const DownloadingStates = {
        Waiting: "Waiting",
        Downloading: "Downloading",
        Error: "Error"
    }

    const [width, setWidth] = useState(5);
    const [height, setHeight] = useState(5);
    const [selectedColours, setSelectedColours] = useState({});
    const [allowDuplicates, setAllowDuplicates] = useState(true);
    const [suggestedColours, setSuggestedColours] = useState([]);
    const [loadingState, setLoadingState] = useState(LoadingStates.Waiting);
    const [downloadingState, setDownloadingState] = useState(DownloadingStates.Waiting);
    const [squareSize, setSquareSize] = useState(100);
    const [resultWidth, setResultWidth] = useState(500);
    const [nonEmptyColours, setNonEmptyColours] = useState({});
    const minWidth = 1280;

    function handleSubmit(event) {
        event.preventDefault();

        setLoadingState(LoadingStates.Loading);

        const nonEmptyColoursTemp = Object.values(selectedColours).filter(el => {
            return el !== '';
        });
        setNonEmptyColours(nonEmptyColoursTemp);

        const squareSizeTemp = nonEmptyColoursTemp.length * 20;
        setSquareSize(squareSizeTemp);
        const resultWidthTemp = width * squareSizeTemp;
        setResultWidth(resultWidthTemp);

        if (nonEmptyColoursTemp.length < 3) {
            setLoadingState(LoadingStates.Error);
            return;
        }

        //setSuggestedColours(TestColours2);
        //setLoadingState(LoadingStates.Loaded);

        fetch("/api/ColourCombinations", {
            method: "POST",
            body: JSON.stringify({
                Width: width,
                Height: height,
                SelectedColours: nonEmptyColoursTemp,
                AllowDuplicates: allowDuplicates
            })
        })
        .then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
            return response.json()
        })
        .then(json => {
            setSuggestedColours(json);
            setLoadingState(LoadingStates.Loaded);
            scrollToResults();
        }).catch(err => {
            setLoadingState(LoadingStates.Error);
        });
    }

    function scrollToResults() {
        document.getElementById("result-view").scrollIntoView({ behavior: "smooth" });
    }

    function onClone(doc) {
        var elem = doc.querySelector("div.html2pdf__container #result-table");
        var resultSquares = doc.getElementById("result-squares");
        if (elem !== null) {
            elem.classList.remove("table"); // For some reason this style messes up the pdf doc
            elem.classList.add("table-print");

            let width = resultSquares.scrollWidth;
            width = width < minWidth ? minWidth : width;
            elem.style["width"] = width + "px";
            elem.style["font-size"] = "20px";
        }
    }

    function handlePdfDownload(event) {
        setDownloadingState(DownloadingStates.Downloading)
        event.preventDefault();

        const filename = `WittyCraftsDesign_GSCP_${Date.now()}.pdf`;
        const resultSquares = document.getElementById("result-squares");
        const resultTable = document.getElementById("result-table");

        const ratio = 1.4142;
        let sw = resultSquares.scrollWidth;
        let srh = resultSquares.scrollHeight;
        let width = sw < minWidth ? minWidth : sw;
        let sm = Math.floor(width * 0.1);
        width = width + (2 * sm);

        const sh = Math.floor(ratio * width);

        let doc = new jsPDF({ orientation: "p", unit: "px", format: [width,sh], hotfixes: ["px_scaling"], putOnlyUsedFonts:true, compress: true });
        let html2CanvasOptions = { scale: 1, onclone: onClone };
        doc.html(resultSquares, { margin: [sm, sm], html2canvas: html2CanvasOptions })
        .then(() => doc.html(resultTable, { y: srh + sm, margin: [sm, sm], autoPaging: 'text', html2canvas: html2CanvasOptions }))
        .then(() => {
            const pageCount = doc.internal.getNumberOfPages();
            
            for (let i = 0; i < pageCount; i++) {
                doc.setPage(i);
                doc.setFontSize(16);
                doc.text(sm, (sm / 2), `${doc.internal.getCurrentPageInfo().pageNumber} / ${pageCount} Witty Crafts Design Granny Square Colour Picker`);
                doc.text("www.wittycraftsdesign.com", doc.internal.pageSize.width / 2, sh - (sm / 2), { align: 'center' });
                // watermark
                doc.saveGraphicsState();
                doc.setGState(new doc.GState({ opacity: 0.1 }));
                doc.setFontSize(148);
                doc.text("Witty Crafts Design", doc.internal.pageSize.width * 0.15, doc.internal.pageSize.height * 0.75, { angle: 45, });
                doc.restoreGraphicsState();
            }
        })
        .then(() => doc.save(filename))
        .then(() => setDownloadingState(DownloadingStates.Waiting));
    }

    function handleWidthChange(event) {
        setWidth(event.target.selectedOptions[0].value);
    }

    function handleHeightChange(event) {
        setHeight(event.target.selectedOptions[0].value);
    }

    function handleSelectedColourChange(event) {
        let temp = { ...selectedColours };
        temp[event.target.name] = event.target.value;
        setSelectedColours(temp);
    }

    function handleAllowDuplicatesChange(event) {

        if (event.target.selectedOptions[0].value === '0') {
            setAllowDuplicates(false);
        } else {
            setAllowDuplicates(true);
        }
    }

    function Results() {
        switch (loadingState) {
            case LoadingStates.Loaded:
                return <>
                    <div className="row table-responsive">
                        <div id="result-squares" className="col-md-12 mt-5">
                            <div style={{ width: resultWidth + 'px', margin: 'auto' }} className="shadow rounded">
                                {suggestedColours.map((colours, index) =>
                                    <div style={{ width: squareSize + 'px', height: squareSize + 'px', float: 'left' }}>
                                        <RenderSquare colours={colours} idx={0} />
                                    </div>
                                )}
                            </div>
                            <div className="clearFix"></div>
                        </div>
                    </div>
                    <div style={{ pageBreakAfter: 'always'}}></div>
                    <div className="row table-responsive mt-5">
                        <table className="table" id="result-table">
                            <thead>
                                <tr>
                                    <th>Square</th>
                                    {nonEmptyColours.map((item, index) => 
                                        <th key={index}>Round {index + 1}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {suggestedColours.map((square, index) =>
                                    <tr>
                                        <td><span>{index + 1}</span></td>
                                        {square.colours.slice(0).reverse().map(colour =>
                                            <td>{Colours.filter(el => { return el.hex === colour }).map(item =>
                                                <span>{item.name} {item.code}</span>
                                            )}</td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
                break;
            case LoadingStates.Loading:
                return <div className="row"><div className="spinner-border m-auto my-5" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                </div>
                break;
            case LoadingStates.Error:
                return <div className="text-danger p-4 fw-bold">Could not find enough combinations to create pattern, try selecting more colours, a smaller size or ensure duplicates is set to Yes</div>
                break;
            case LoadingStates.Waiting:
            default:
                break;

        }
    }
    // TODO move to seperate component
    function RenderSquare(props) {
        const nextId = props.idx + 1;
        if (props.idx < props.colours.colours.length) {
            return <div style={{ border: '10px solid ' + props.colours.colours[props.idx] }}>
                <RenderSquare colours={props.colours} idx={nextId} />
            </div>
        }
    }
    // TODO move to seperate component
    function DownloadPDFButton() {

        if (loadingState === LoadingStates.Loaded) {
            if (downloadingState === DownloadingStates.Downloading) {
                return <button id="download-pdf" type="button" className="btn btn-secondary" disabled>
                    <span className="spinner-border spinner-border-sm d-print-none" role="status" aria-hidden="true"></span>
                     Downloading
                </button>
            } else {
                return <button id="download-pdf" type="button" onClick={handlePdfDownload} className="btn btn-secondary">
                    Download
                </button>
            }        }
    }

    return (
        <div className="text-start">
            <h1>Granny Square Colour Picker</h1>
            <p>This tool generates a non repeating pattern of squares using <a href="https://www.lovecrafts.com/en-gb/p/stylecraft-special-dk?utm_medium=affiliate&a_aid=c474ca6e" target="_blank">Stylecraft colours</a>. When I was designing my <a href="/patterns/spiced-biscuits-blanket" target="_blank">Spiced Biscuits Blanket pattern</a>. I wanted to make sure that no two squares with the same colour combination were placed next to each other.</p>
            <p>It will ensure that the last round in adjacent squares is different, this won’t always be the case for squares placed diagonally. Simply select 3 or more colours and specify how many squares you want on each side. If you want all squares to be unique then select No under the duplicates option (you will need to specify at least 5 colours to ensure this can be achieved).</p>
            <form onSubmit={handleSubmit} className="row d-print-none" method="post">
                <div className="row">
                    {Array.from(Array(8), (_,index) => index + 1).map(i => 
                        <div key={i} className="col-md col-6 text-center">
                            <label className="form-label fw-bold" htmlFor={`Colour${i}`}>Colour {i}</label>
                            <ColourPicker key={`Colour${i}`} name={`Colour${i}`} onChange={handleSelectedColourChange} />
                        </div>
                    )}
                </div>
                <div className="row mt-3">
                    <div className="col-md-2 col-6">
                        <label htmlFor="Width" className="form-label fw-bold">Width</label>
                        <select name="Width" defaultValue="5" onChange={handleWidthChange} className="form-control">
                            {Array.from(Array(10), (_, index) => index + 3).map(i =>
                                <option key={i}>{i}</option>
                            )}
                        </select>
                        <div className="form-text">Number of squares accross</div>
                    </div>
                    <div className="col-md-2 col-6">
                        <label htmlFor="Height" className="form-label fw-bold">Height</label>
                        <select name="Width" defaultValue="5" onChange={handleHeightChange} className="form-control">
                            {Array.from(Array(10), (_, index) => index + 3).map(i =>
                                <option key={i}>{i}</option>
                            )}
                        </select>
                        <div className="form-text">Number of squares tall</div>
                    </div>
                    <div className="col-md-6 col-12">
                        <label htmlFor="AllowDuplicates" className="form-label fw-bold">Duplicates</label>
                        <div className="input-group">
                            <select name="AllowDuplicates" defaultValue="5" onChange={handleAllowDuplicatesChange} className="form-control">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <input type="submit" value="Create Pattern" className="btn btn-primary" />
                            <DownloadPDFButton />
                        </div>
                        <div className="form-text">Select No if all squares should be unique</div>
                    </div>
                </div>
            </form>
            <div id="result-view"></div>
            <Results />
        </div>
    );
}
export default ColourCombinations